import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import Layout from "../components/Layout";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  title,
  subheading,
  singleToothImage,
  partialImage,
  fullArchImage,
}) => {
  return (
    <div>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content row">
                  <div
                    className="content front-page-content d-flex"
                    style={{ alignItems: "flex-end" }}
                  >
                    <div className="tile" style={{ flexBasis: "30%" }}>
                      <h1 className="title">{title}</h1>
                    </div>
                    <div className="tile">
                      <h3 className="subtitle">{subheading}</h3>
                    </div>
                  </div>
                  {/* @todo: map these instead of hardcoding them. */}
                  <div className="front-page-content d-flex">
                    <div className="col frontpage-col">
                      <Link to="workflow/single-tooth">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: singleToothImage,
                            alt: "Single Tooth Replacement",
                            width:
                              singleToothImage.childImageSharp.gatsbyImageData
                                .width,
                            height:
                              singleToothImage.childImageSharp.gatsbyImageData
                                .height,
                          }}
                        />
                      </Link>
                      <div className="align-text-center">
                        <h2>SINGLE TOOTH</h2>
                        <p>DS SIGNATURE WORKFLOW</p>
                      </div>
                    </div>
                    <div className="col frontpage-col">
                      <Link to="workflow/partial">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: partialImage,
                            alt: "Partial Tooth Replacement",
                            width:
                              partialImage.childImageSharp.gatsbyImageData
                                .width,
                            height:
                              partialImage.childImageSharp.gatsbyImageData
                                .height,
                          }}
                        />
                      </Link>
                      <div className="align-text-center">
                        <h2>PARTIAL</h2>
                        <p>DS SIGNATURE WORKFLOW</p>
                      </div>
                    </div>
                    <div className="col frontpage-col">
                      <Link to="workflow/full-arch">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: fullArchImage,
                            alt: "Full Arch Replacement",
                            width:
                              fullArchImage.childImageSharp.gatsbyImageData
                                .width,
                            height:
                              fullArchImage.childImageSharp.gatsbyImageData
                                .height,
                          }}
                        />
                      </Link>
                      <div className="align-text-center">
                        <h2>FULL ARCH</h2>
                        <p>DS SIGNATURE WORKFLOW</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  singleToothImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  partialImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  fullArchImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        singleToothImage={frontmatter.singleToothImage}
        partialImage={frontmatter.partialImage}
        fullArchImage={frontmatter.fullArchImage}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        singleToothImage {
          childImageSharp {
            gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
          }
        }
        partialImage {
          childImageSharp {
            gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
          }
        }
        fullArchImage {
          childImageSharp {
            gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
